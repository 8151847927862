const NUMBER_GROUP_REGEX = /\+?1?[- ]?\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})/;

// Check for 6 vs 5 digit codes.
const POSTAL_REGEX = /[a-z][0-9][a-z][0-9][a-z][0-9]/i;

export const WHOLE_NUMBER_OPTIONS = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
};

export const formatPhoneLink = (num: string, prefix = 'tel') =>
  num.replace(NUMBER_GROUP_REGEX, `${prefix}:+1-$1-$2-$3`);

export const formatPhoneNumber = (num: string) =>
  num.replace(NUMBER_GROUP_REGEX, '($1) $2-$3');

/*
 * Handle the formatting of a US ZIP (e.g. '90292') or CA ZIP (e.g. 'V3H0K9')
 * code by stripping any non-ZIP characters.
 */
export const formatZIP = (input: string): string => {
  const code = input.replace(/[^a-z0-9]/gi, '');
  const length = code.match(POSTAL_REGEX) ? 6 : 5;
  return code.slice(0, length).toUpperCase();
};

export const formatCurrency = (
  amount: number,
  options?: Intl.NumberFormatOptions,
) =>
  amount.toLocaleString('en', {
    ...options,
    style: 'currency',
    currency: 'USD',
  });
